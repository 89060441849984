import React,{useState,Component,useRef,useEffect,history} from 'react'

import {View,Text,ScrollView, FlatList,StyleSheet,Image,TouchableOpacity
,Linking
} from 'react-native'
import { whiteicons ,shoes, blackicon,sizes, icons, api} from '../assets/assets';

import { styles } from '../assets/styles'
import {useDispatch,useSelector} from "react-redux";
import UpNavBar from '../navbar/UpNavBar';
import { Link, useParams,useLocation } from 'react-router-dom';
import axios from 'axios';
import Loading from './Loading';
import SnapPixel from 'next-snapchat-pixel';
import qrcode from '../assets/icons/qrcode.png'




const PrintBill =()=>{
  const dispatch = useDispatch();
  const[order,setorder]=useState([])
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const[items,setItems]=useState([])
    const [loading,setloading]=useState(1)

    const effectRan = useRef(false);
   
    const currentDate = new Date();
    const formattedTime = currentDate.toLocaleTimeString();


  useEffect(() => {
    // SnapPixel.init('870403a3-9ea6-4a5d-994f-6b82fd0d9dce');
    // SnapPixel.track('PURCHASE');
    
    
    if (!effectRan.current) {
        window.scrollTo(0, 0);
        axios({
            method: "post",
            url: api+"getorders",
            headers: { 
                'Accept': 'application/json',
                'Authorization': 'Bearer '
            },
            data:{
                from :"2023-06-17",
                to:"2029-06-18",
                id:id,
                csphone:"",
                paid:"",
                state:""
            }
        })
        .then((response) => {
             console.log(response.data[0].order);
             setorder(response.data[0].order)
             setItems(response.data[0].items)
    
             axios({
                method: "post",
                url: api+"updateorder",
                headers: { 
                    'Accept': 'application/json',
                    'Authorization': 'Bearer '
                },
                data:{
                    id:String(response.data[0].order.id),
                    company_id:"1",
                    date:String(response.data[0].order.date),
                    state:"received",
                    paid:String(response.data[0].order.paid),
                    paidmethode:"knet",
                    sellerCode:"ecommerce website",
                    csname:String(response.data[0].order.csname),
                    csphone:String(response.data[0].order.csphone),
                    csemail:String(response.data[0].order.csemail),
                    address:String(response.data[0].order.address),
                    country:String(response.data[0].order.country),
                    address1:"test",
                    address2:"test",
                    address3:"test",
                    address4:"test",
                    address5:"test",
                    address6:"test",
                    address7:"test",
                    address8:"test5",
                    notes:"0",
                    discountCode:"0",
                    totalAmount:String(response.data[0].order.totalAmount),
                  }
            })
            .then((response) => {
                 console.log(response.data);
                 //alert("yes")
                 setTimeout(window.print, 5000);
                 setTimeout(()=>window.close(), 10000);

                 
                 

            })
            .catch((error) => {
                console.log(error);
            });
    
    
    
    
    
             
        })
        .catch((error) => {
            console.log(error);
        });
      }
    
      return () => effectRan.current = true;


    

    
  
  }, [])

  
     
    

    return(
        <View style={styles.shoeContainerDetails}             
        >

          <View style={{width:"97%",alignSelf:"center",borderWidth:0.5,borderColor:"balck",margin:10
        ,padding:10
        }}>
            <Image
        source={icons.logoicon}
        style={{
            width:"50%",
            height:250,

           alignSelf:"center",
           resizeMode: 'contain',

            
            }}   />

              <Text style={{fontWeight:"bold",fontSize:18,marginBottom:20}}>
                #ID{order.id}
            </Text>


            <View style={{flexDirection:"row",padding:10,
            borderWidth:0.3,borderColor:"black"        
        }}>
            <Text style={{fontSize:15,fontWeight:"bold"}}>
                customer name :  
            </Text>
            <Text style={{fontSize:15,color:"gray"}}>
            {order.csname}
            </Text>
            </View>


            <View style={{flexDirection:"row",padding:10,
            borderWidth:0.3,borderColor:"black"        
        }}>
            <Text style={{fontSize:15,fontWeight:"bold"}}>
                phone :  
            </Text>
            <Text style={{fontSize:15,color:"gray"}}>
            {order.csphone}
            </Text>
            </View>
            {/* <View style={{flexDirection:"row",padding:10,
            borderWidth:0.3,borderColor:"black"        
        }}>
            <Text style={{fontSize:15,fontWeight:"bold"}}>
                country :  
            </Text>
            <Text style={{fontSize:15,color:"gray"}}>
            {order.country}
            </Text>
            </View>
           */}
          <View style={{flexDirection:"row",padding:10,
            borderWidth:0.3,borderColor:"black"        
        }}>
            <Text style={{fontWeight:"bold",fontSize:15,marginBottom:10}}>
               Date : 
            </Text>
            <Text style={{fontSize:15,color:"gray"}}>
            {order.date}| Time : {formattedTime}

            </Text>
            </View>

            <View style={{marginBottom:10,padding:10,
            borderWidth:0.5,borderColor:"black"        
        }}>



{items.map((item, index) => (
    <View style={{borderBottomWidth:0.5,borderBottomColor:"black"}}>
                    <View style={{flexDirection:"row",margin:5,marginVertical:10
                    ,justifyContent:"space-between"
                    }}>
                    <Text >
                    {item.name_ar}    {item.qty}  
                    </Text>

                  
                   

                    <Text style={{marginHorizontal:2}}>
                      {item.total_amount} KD
                    </Text>


                    </View>
                 
                    <Text style={{marginHorizontal:2}}>
               {item.at1_value}
                    </Text>
    </View>

                    ))}
            
            </View>
            <Text style={{fontSize:15,fontWeight:"bold"}}>
                 notes :  {order.notes}
            </Text>

            <Text style={{fontSize:15,fontWeight:"bold" }}>
                
            </Text>
           
            <Text style={{fontSize:15,fontWeight:"bold"}}>
                area :   {order.country}
            </Text>
         
            <Text style={{fontSize:15,fontWeight:"bold"}}>
            address 
            </Text>
            <Text style={{fontSize:15,fontWeight:"bold"}}>
            {order.address}
            </Text>

            <Text style={{fontWeight:"bold",fontSize:15,marginBottom:10}}>
              delevery : 1 KD 
            </Text>
         
            <Text style={{fontWeight:"bold",fontSize:15,marginBottom:10}}>
               total amount :  {order.totalAmount} KWD
            </Text>

            <View style={{borderWidth:0.5,borderColor:"black",padding:10}}>


            <Text style={{fontWeight:"bold",fontSize:15,marginBottom:10}}>
              call us : 50202078 
            </Text>

            <Text style={{fontWeight:"bold",fontSize:15,marginBottom:10}}>
              website : 
            </Text>

            <Image
        source={qrcode}
        style={{
            width:250,
            height:250,

           alignSelf:"center",
           resizeMode: 'contain',

            
            }}   />

</View>

          </View>

           


       
       
    
    
       </View>
    )
}

export default PrintBill;