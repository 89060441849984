import React,{useState,Component,useRef,useEffect} from 'react'

import {View,Text,ScrollView, FlatList,StyleSheet,Image,TouchableOpacity
,Linking,TextInput
} from 'react-native'
import { whiteicons ,shoes, blackicon,sizes, icons, api} from '../assets/assets';

import { styles } from '../assets/styles'
import {useDispatch,useSelector} from "react-redux";
import UpNavBar from '../navbar/UpNavBar';
import { Link, useParams ,route,useNavigate} from 'react-router-dom';
import axios from 'axios';
import Loading from './Loading';
import SnapPixel from 'next-snapchat-pixel';


import { motion } from "framer-motion";


const ProductDetails =()=>{
  const dispatch = useDispatch();
  const {id}=useParams()
  const[loading,setloading]=useState(1)   
   const cartItems=useSelector(state =>state.cartReducer.cartItems);


   let navigate = useNavigate();

    const[item,setitem]=useState()
    const [active,setactive]=useState({id:5,size:36})
    const[qty,setqty]=useState(1);

    const[activeimg,setactiveimg]=useState()

    const cartViewBox=useSelector(state =>state.cartReducer.cartViewBox);

      const [notes,setNotes]=useState("no notes")
    const country=useSelector(state =>state.customerReducer.country);
    const curency=useSelector(state =>state.customerReducer.curency);
    const rates=useSelector(state =>state.customerReducer.rates);

    useEffect(() => {
      window.scrollTo(0, 0);
   
      // SnapPixel.init('870403a3-9ea6-4a5d-994f-6b82fd0d9dce');
      // SnapPixel.track('VIEW_CONTENT');


      dispatch({type : "closeCartViewBox"})

      axios({
        method: "get",
        url: api+"items/"+id,
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        },
    })
    .then((response) => {
         setitem(response.data)
         setactiveimg(response.data.image1)
         setloading(0)
    })
    .catch((error) => {
        console.log(error);
    })
      
    
}, []) 
  
     
    

    return(
      loading==1?<Loading/>:
        <View style={styles.shoeContainerDetails}>    
        <View  style={{
          flexDirection:"row" , justifyContent:"space-between"
          ,width:"100%",
          marginBottom:10
        }}>
        <Link
      to="/"
      style={{margin:10,textDecoration: 'none'}}

      >
      <View
            >
            <Image
           source={blackicon.blackback}
           style={{
               width:30,
               height:30
               }}   />
               
            </View>
      </Link>
      <TouchableOpacity 
        
            onPress={()=>          navigate("Cart")
          }

style={{padding:10}}    
   >
    <View             style={{flexDirection:"row"}}
>       <Text style={{fontSize:20,color:"#3F9952"
}}>{
                    cartItems.length==0?null:cartItems.length
                }</Text>
            <Image
        source={blackicon.blackbag}
        style={{
            width:30,
            height:30,
           
            
            }}   />

    </View>
         
            </TouchableOpacity>
          </View>  
   
          
       
         <Image style={styles.shoeImageDetails} source={{ uri: activeimg }} />
       
         {/* <View style={{width:"90%",padding:10,flexDirection:"row"}}>
           <ScrollView 
       style={{width:"90%"}}
       horizontal={true}
       showsHorizontalScrollIndicator={false}
       >
        <TouchableOpacity  style={{margin:5}}
                onPress={()=>setactiveimg(item['image1'])}

        >
        <Image style={{width:60,height:60,resizeMode:"cover" ,borderRadius:10}} source={{ uri: item['image1']}} />
        </TouchableOpacity>

        <TouchableOpacity  style={{margin:5}}
        onPress={()=>setactiveimg(item.image2)}
        >
        <Image style={{width:60,height:60,resizeMode:"stretch"}} source={{ uri: item.image2 }} />
        </TouchableOpacity>

        <TouchableOpacity  style={{margin:5}}
                onPress={()=>setactiveimg(item.image3)}

        >
        <Image style={{width:60,height:60,resizeMode:"stretch"}} source={{ uri: item.image3}} />
        </TouchableOpacity>

        <TouchableOpacity  style={{margin:5}}
                onPress={()=>setactiveimg(item.image4)}
        >
        <Image style={{width:60,height:60,resizeMode:"stretch"}} source={{ uri: item.image4 }} />
        </TouchableOpacity>
                 
                 

         
         
       </ScrollView>
         </View> */}













         <View style={{justifyContent:"space-between" ,
         padding:10}}>
          <View style={{width:"100%"}}>
          <Text style={styles.shoeName}>{item.name_ar}
          </Text>
          <Text style={{marign:5}}>{item.description_ar}
          </Text>
          {/* <Text style={{textAlign:"left"}}>code : {item.id}</Text> */}

          <View >
       
{item.discount==0?null:
  <Text style={{fontSize:14,fontWeight:"bold",margin:5,textDecorationLine: 'line-through',
  textDecorationColor: 'red',}}>
{Number(item.amount)} د.ك
   </Text>
}
     
    <Text style={{fontSize:17,fontWeight:"bold",margin:5,color:"#3F9952"}}>
        {Number(item.amount-item.discount)} د.ك
                  </Text>

       </View>
          </View>

    <View style={{flexDirection:"row",justifyContent:"space-between",width:"100%"}}>
      <View style={{flexDirection:"row",justifyContent:"space-between",alignItems:"center"
      ,width:100}}>
        <TouchableOpacity styles={{margin:5}}
        onPress={()=>{setqty(qty+1)}}
        >
        <Text  style={{fontSize:18,color:"#3F9952"}}>+</Text>

          </TouchableOpacity>

        <Text  style={{margin:5}}>{qty}</Text>

        <TouchableOpacity styles={{margin:5}}
        onPress={()=>qty==1?null:setqty(qty-1)}
        >
        <Text  style={{fontSize:18,color:"#3F9952"}}>-</Text>

          </TouchableOpacity>        
      </View>
    <Text >لديك ملاحظات اضافية
          </Text>
    </View>
          
          <TextInput
        placeholder="الملاحظات"
        style={{width:"100%",alignSelf:"center",backgroundColor:"white",
        padding:20,borderRadius:10,flexDirection:"row",
        borderWidth:1,borderColor:"#3F9952",
        justifyContent:"space-between",
        fontSize:14,fontWeight:"bold",color:"#3F9952",fontFamily:"Almarai-Bold",textAlign:"right",margin:20
, outline: 'none'
        }}
        value={notes}
        onChangeText={setNotes}

      />


      
        
         </View>
      

        

      






         <TouchableOpacity
         style={{
          width:"90%",
          alignSelf:"center",
          backgroundColor:"#002F25",
       padding:20,
       borderRadius:10,flexDirection:"row",
       justifyContent:"space-between",
       position: 'sticky',
       bottom: 10
       }}
      
       onPress={()=>
       {

        dispatch({
          type : "addToCart",
          payload:{
              id: item.id,
              item_id:item.id,
              name_ar: item.name_ar,
              image1: item['image1'],
              amount:Number(item.amount)-Number(item.discount),
              totalamount:(Number(item.amount)-Number(item.discount))*qty,
              at1value:notes,
              
              at1_value:notes,
              at2_value:"0",
              at3_value:"0",
              at4_value:"0",
              at5_value:"0",
              at6_value:"0",
              at7_value:"0",
              at8_value:"0",

              at1_price:"0",
              at2_price:"0",
              at3_price:"0",
              at4_price:"0",
              at5_price:"0",
              at6_price:"0",
              at7_price:"0",
              at8_price:"0",


              qty:qty
          }})
          navigate("/")
               }
        
    }
         >
          
          
           
          <Text style={{fontSize:14,fontWeight:"bold",color:"white",fontFamily:"Almarai-Bold"}}>
            {(Number(item.amount)-Number(item.discount))*qty}
            د.ك
            </Text>
               <Text style={{fontSize:14,fontWeight:"bold",color:"white",fontFamily:"Almarai-Bold"}}>
            إضافة للسلة</Text>
         </TouchableOpacity>
        


              {/* cart view box */}
            
    
    
       </View>
    )
}

export default ProductDetails;