import {combineReducers} from "redux";
import language from './reducer/language'
import cartReducer from "./reducer/cartReducer";
import customerReducer from "./reducer/customerReducer";


const rootReducer = combineReducers({
    language:language,
    cartReducer:cartReducer,
    customerReducer:customerReducer
})


export default rootReducer;