import React,{useState,Component,useRef
,useEffect
} from 'react'

import {View,Text,ScrollView, FlatList,StyleSheet,Image,TouchableOpacity} from 'react-native'
import { whiteicons ,shoes, blackicon,sizes} from '../assets/assets';
import { styles } from '../assets/styles'
import {useDispatch,useSelector} from "react-redux";
import { Link, useParams } from 'react-router-dom';
import SnapPixel from 'next-snapchat-pixel';


const Cart =()=>{
  const cartItems=useSelector(state =>state.cartReducer.cartItems);

  const totalAmount=useSelector(state =>state.cartReducer.totalAmount);
  const deleveryCost=1
  const dispatch = useDispatch();

  const country=useSelector(state =>state.customerReducer.country);
  const curency=useSelector(state =>state.customerReducer.curency);
  const rates=useSelector(state =>state.customerReducer.rates);
  useEffect(() => {

    // SnapPixel.init('870403a3-9ea6-4a5d-994f-6b82fd0d9dce');
    // SnapPixel.track('ADD_CART');

  
  }, [])
    return(
      <View style={{width:"100%",padding:5}}>
      <Link
      to="/"
        style={{alignSelf:"flex-start",margin:10,textDecoration: 'none'
      }}
        >
        <Image
       source={blackicon.blackback}
       style={{
           width:40,
           height:40
           }}   />
        </Link>

       

   
{cartItems.map((item,index)=>
 <View style={{flexDirection:"row",justifyContent:"space-between",maxWidth:"100%"
 ,padding:5,
 marginVertical:20

  }}>
      <Image style={{width:110,height:110,borderRadius:15}} 
      source={{uri: item.image1 }} />





<View>
<Text style={{fontSize:17,margin:5,maxWidth:150
  ,fontFamily:"Almarai-Bold"}}>
          {item.name_ar}  {Number(item.amount)} د.ك 
          </Text>
          <Text style={{fontSize:17,margin:5}}>
          ملاحظات : {item.at1value}
          </Text>
         

<View
 style={{flexDirection:"row",
 justifyContent:"space-between",alignItems:"center"
 
 }} 
>
<Text style={{fontSize:17,marginRight:25}}>
  {/* {item.amount} KD */}
  المجموع :
  {Number(item.amount)*item.qty} 
  
</Text>
<TouchableOpacity
 onPress={()=>
  dispatch({
    type : "plusToCart",
    payload:{
      
        id: item.id,
        item_id:item.item_id,
        name_ar: item.name_ar,
        image1: item.image1,
        amount:item.amount,
        at1value:item.at1value,
        at2value:"null",
        at3value:"null",
        at4value:"null",
        qty:1

      
    }})
}
 >
<Text style={{fontSize:17,fontWeight:"bold",color:"#002F25",margin:5}}>
      +
  </Text>
  </TouchableOpacity>
  <Text style={{fontSize:17,fontWeight:"bold",margin:5}}>
      {item.qty}
  </Text>
  <TouchableOpacity
 onPress={()=>
  dispatch({
    type : "decrementQty",
    payload:{
      
        id: item.id,
        item_id:item.item_id,
        name_ar: item.name_ar,
        image1: item.image1,
        amount:item.amount,
        at1value:item.at1value,
        at2value:"null",
        at3value:"null",
        at4value:"null",
        qty:item.qty

      
    }})}
 >
   <Text style={{fontSize:17,fontWeight:"bold",color:"#002F25",margin:5}}>
      -
  </Text>
 </TouchableOpacity>


 
</View>

</View>



          

      </View>
     

)}
  <Text style={{fontSize:14,fontFamily:"Almarai-Regular",color:"black",fontFamily:"Almarai-Bold",margin:10}}>
       رسوم التوصيل  : 1 د.ك
       
    </Text>
       <View style={{
        flexDirection:"row",
        justifyContent:"space-between",
        alignItems:"center",padding:20
       }}>
        {
          Number(totalAmount)<4.5?
          <Link
          style={{width:"55%",
        textDecoration: 'none'
 
        }}
       
          >
           <View
           style={{width:"100%",alignSelf:"center",backgroundColor:"#002F25",
           padding:20,borderRadius:10,flexDirection:"row",
           justifyContent:"space-between" ,textDecoration: 'none'}}
           >
                  <Image
            source={whiteicons.cart}
            style={{
                width:20,
                height:20,
                }}   />
                <Text style={{fontSize:14,fontWeight:"bold",color:"white",fontFamily:"Almarai-Bold"}}>
             اقل طلب ٤.٥ د.ك</Text>
 
           </View>
            
       
          </Link>
          :
          <Link
          to="InfoCustomer"
          style={{width:"55%",
        textDecoration: 'none'
 
        }}
       
          >
           <View
           style={{width:"100%",alignSelf:"center",backgroundColor:"#002F25",
           padding:20,borderRadius:10,flexDirection:"row",
           justifyContent:"space-between" ,textDecoration: 'none'}}
           >
                  <Image
            source={whiteicons.cart}
            style={{
                width:20,
                height:20,
                }}   />
                <Text style={{fontSize:14,fontWeight:"bold",color:"white",fontFamily:"Almarai-Bold"}}>
             اكمال الشراء</Text>
 
           </View>
            
       
          </Link>

        }
        
         <View style={{
          borderColor:"#3F9952",borderWidth:1,padding:20,borderRadius:10
         }}>
  <Text style={{fontSize:14,fontFamily:"Almarai-Regular"
  ,color:"#3F9952",fontFamily:"Almarai-Bold"}}>
           المجموع : 
         
{Number(totalAmount)+Number(deleveryCost)} KD
           
        </Text>
         </View>
      
       
       </View>


    
        </View>
    )
}
export default Cart;