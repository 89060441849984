const initalState = {
    cartItems:[
        // {
        //     id: 1,
        //     item_id:10,
        //     name_ar: "Nike Air Force 1",
        //     image1: "https://prod.aaw.com/media/catalog/product/c/2/c20bd4b47a1e07e740e5398edcaaaed2b66c38010f8aaaec4b4a3cecda5487f2.jpeg",
        //     amount:50,
        //     at1value:"35",
        //     at2value:"black",
        //     at3value:"black",
        //     at4value:"black",
        //     qty:1

        //   }
    ],
    totalAmount:0,
    cartViewBox:0

};

const cartReducer=(state = initalState,action)=>{
const {type,payload}=action;
//payload is the despatch function to do in the state

switch(type){


    case "addToCart":{
        const cartQtyTotal = state.cartItems.reduce((total, cartItem) => total + cartItem.qty, 0);
        if (cartQtyTotal >= 600) {
          alert("Cart is full. You can't add more items.");
        }
        else{
            const existingItem = state.cartItems.find((cartItem) => cartItem.item_id === payload.item_id 
            && cartItem.at1value === payload.at1value
            // && cartItem.at2value === payload.at2value
            // && cartItem.at3value === payload.at3value
            // && cartItem.at4value === payload.at4value
            );
            if (existingItem) {
              const updatedcartItems = state.cartItems.map((cartItem) =>
                cartItem.item_id === payload.item_id 
                && cartItem.at1value === payload.at1value
                // && cartItem.at2value === payload.at2value
                // && cartItem.at3value === payload.at3value
                // && cartItem.at4value === payload.at4value
                ? { ...cartItem, qty: cartItem.qty + 1 
                ,totalamount:cartItem.amount*(cartItem.qty + 1),

                } : cartItem
              );
              return{
                cartItems:updatedcartItems,
                totalAmount : Number(state.totalAmount) + Number(payload.amount),
                cartViewBox:1

            }
    
            } else {
              const updatedcartItems = [...state.cartItems, { ...payload}];
              // const updatedcartItems = [...state.cartItems, { ...payload, qty: cartItem.qty}];

              return{
                cartItems:updatedcartItems,
                totalAmount : Number(state.totalAmount) + ( Number(payload.amount)*payload.qty),
                cartViewBox:1
            }
    
            }
             //setTotalAmount(totalAmount + payload.amount);
        }
    }

    case "plusToCart":{
        const cartQtyTotal = state.cartItems.reduce((total, cartItem) => total + cartItem.qty, 0);
        if (cartQtyTotal >= 600) {
          alert("Cart is full. You can't add more items.");
        }
        else{
            const existingItem = state.cartItems.find((cartItem) => cartItem.item_id === payload.item_id 
            && cartItem.at1value === payload.at1value
            // && cartItem.at2value === payload.at2value
            // && cartItem.at3value === payload.at3value
            // && cartItem.at4value === payload.at4value
            );
            if (existingItem) {
              const updatedcartItems = state.cartItems.map((cartItem) =>
                cartItem.item_id === payload.item_id 
                && cartItem.at1value === payload.at1value
                // && cartItem.at2value === payload.at2value
                // && cartItem.at3value === payload.at3value
                // && cartItem.at4value === payload.at4value
                ?  { ...cartItem, qty: cartItem.qty + 1 
                  ,totalamount:cartItem.amount*(cartItem.qty + 1),
  
                  } : cartItem
              );
              return{
                cartItems:updatedcartItems,
                totalAmount : Number(state.totalAmount) + Number(payload.amount)

            }
    
            } else {
              const updatedcartItems = [...state.cartItems, { ...payload, qty: 1 }];
              return{
                cartItems:updatedcartItems,
                totalAmount : Number(state.totalAmount) + Number(payload.amount)
            }
              console.log("im here 2")
    
            }
             //setTotalAmount(totalAmount + payload.amount);
        }
    }

    case "decrementQty" :{
      const updatedCartItems = state.cartItems.map((cartItem) => {
        if (cartItem.item_id === payload.item_id
          && cartItem.at1value === payload.at1value
          ) {
            // console.log({...cartItem, 
            //   qty: cartItem.qty - 1,
            //   totalamount:cartItem.amount*(cartItem.qty - 1)})
          return { 

            ...cartItem, 
            qty: cartItem.qty - 1,
            totalamount:cartItem.amount*(cartItem.qty - 1),

          
          };
        }else{
          return{
            ...cartItem
          }
        }
      
      });
     // console.log(updatedCartItems)
      return{
        cartItems:updatedCartItems.filter((cartItem) => cartItem.qty > 0),
        totalAmount : Number(state.totalAmount) - Number(payload.amount)
    }
      
    }

    case "closeCartViewBox":{
    return {
      ...state,cartViewBox:0
    }
    }


}



return state;
}
export default cartReducer;