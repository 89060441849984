import React,{useState,Component,useRef,useEffect} from 'react'

import {View,Text,ScrollView, FlatList,StyleSheet,Image,TouchableOpacity
,TextInput,Linking,Picker
} from 'react-native'
import { whiteicons ,shoes, blackicon,sizes, api, icons} from '../assets/assets';
import { styles } from '../assets/styles'
import {useDispatch,useSelector} from "react-redux";
import axios from 'axios';
import { Link } from 'react-router-dom';
import SnapPixel from 'next-snapchat-pixel';


const InfoCustomer =()=>{
  const cartItems=useSelector(state =>state.cartReducer.cartItems);
  const totalAmount=useSelector(state =>state.cartReducer.totalAmount);
  const today = new Date();
const [loading,setloading]=useState(0)
  const [csName,setCsname]=useState("")
  const [csphone,setcsphone]=useState("")
  const [csemail,setcsemail]=useState("")
  const [country,setcountry]=useState("0")
  const [address,setaddress]=useState("")
const [notes,setnotes] =useState("no notes")

  const [keyphone,setkeyphone]=useState("+965")

  const [atArray, setAtArray] = useState([
  ]);


  const dispatch = useDispatch();

  function arrayToText(array) {
    const text = array.map(item => JSON.stringify(item)).join('\n');
    return text;
  }

  useEffect(() => {

axios({
            method: "get",
            url: api+"areas",
            headers: { 
                'Accept': 'application/json',
                'Authorization': 'Bearer '
            }
        })
        .then((response) => {
          setAtArray(response.data)
        })
        .catch((error) => {
            console.log(error);
        });

  
  }, [])

 
  

  const makeOrder=()=>{
    if (
      csName !== '' &&
      csphone !== '' &&
      country !== '0' &&
      address !== '' 
     
    ){
      setloading(1)
      axios({
        method: "post",
        url: api+"orders",
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        },
        data:{
          company_id:"1",
          date:today.getFullYear()+"-"+(today.getMonth() + 1)+"-"+today.getDate(),
          state:"request",
          paid:"no",
          paidmethode:"knet",
          sellerCode:"ecommerce website",
          csname:csName,
          name:csName,
          csphone:csphone,
          csemail:csemail==null||csemail&&csemail.length<1
          ||csemail==undefined||csemail==""
          ?'noemail@flyfoot.com':csemail,
          address:address,
          country:country,
          address1:"test",
          address2:"test",
          address3:"address3",
          address4:"test",
          address5:"test",
          address6:"test",
          address7:"test",
          address8:"test5",
          notes:notes,
          discountCode:"null",
          totalAmount:Number(totalAmount+1),
          items:cartItems
  
  
        }
    })
    .then((response) => {
         console.log(response.data);
         axios({
          method: "post",
          url: api + 'UpaymentApi',
          headers: {
            'Accept': 'application/json',
          },
          data: {
            amount: Number(totalAmount+1),
            trackid: response.data.id,
            csphone:csphone,
            csname : csName,
            csemail:csemail
          },
        })
          .then((response) => {
            window.open(response.data["paymentURL"],"_self")
            // setloading(0)
            setTimeout(() => {
              setloading(0);
            }, "7000");
          })
          .catch((error) => {
            console.log(error);
            console.log("subscribe");
  
        }) 
    })
    .catch((error) => {
        console.log(error);
    });
    }else{
      if(country=='0'){
        alert("هذه المنطقة غير متاحة حاليا")
      }
      else{
        alert("اكمل البيانات المطلوبة")

      }
    }

  }
    return(
      loading==1?
      <View style={{
        alignItems:"center",padding:30,height:"100%"
      }}>
           <Image
       source={icons.loadingicon}
       style={{
           width:200,
           height:200,margin:10
           }}   />
             <Text style={{fontSize:14,fontWeight:"bold",color:"black",fontFamily:"Almarai-Bold",textAlign:"right"}}>
          جاري التحويل لبوابة الدفع
           </Text>

      </View>
      :
        <View >
        <Link
        to="/Cart"
        style={{alignSelf:"flex-start",margin:10}}
        >
        <Image
       source={blackicon.blackback}
       style={{
           width:40,
           height:40
           }}   />
        </Link>

        <View style={{
        padding:20
       }}>
        
        <Text style={{fontSize:14,fontWeight:"bold",color:"black",fontFamily:"Almarai-Bold",textAlign:"right"}}>
            الاسم الثلاثي 
            <Text style={{fontSize:14,fontWeight:"bold",color:"red",fontFamily:"Almarai-Bold",textAlign:"right"}}
            >*</Text></Text>
         <TextInput
        value={csName}
         onChangeText={setCsname}
         autoFocus={false}
        placeholder="الاسم "
        style={{width:"100%",alignSelf:"center",backgroundColor:"white",
        padding:20,borderRadius:10,flexDirection:"row",
        borderWidth:0.5,borderColor:"#3F9952",
        justifyContent:"space-between",
        fontSize:14,fontWeight:"bold",color:"gray",fontFamily:"Almarai-Bold",textAlign:"right",
        margin:20, outline: 'none'
        }}
      />



<Text style={{fontSize:14,fontWeight:"bold",color:"black",fontFamily:"Almarai-Bold",textAlign:"right"}}>
            رقم الهاتف 
            <Text style={{fontSize:14,fontWeight:"bold",color:"red",fontFamily:"Almarai-Bold",textAlign:"right"}}
            >*</Text></Text>
<TextInput
        value={csphone}
         onChangeText={setcsphone}
         autoFocus={false}
        placeholder="رقم الهاتف"
        style={{width:"100%",alignSelf:"center",backgroundColor:"white",
        padding:20,borderRadius:10,flexDirection:"row",
        borderWidth:0.5,borderColor:"#3F9952",
        justifyContent:"space-between",
        fontSize:14,fontWeight:"bold",color:"gray",fontFamily:"Almarai-Bold",textAlign:"right",margin:20
, outline: 'none'
        }}
      />

<Text style={{fontSize:14,fontWeight:"bold",color:"black",fontFamily:"Almarai-Bold",textAlign:"right"}}>
           الملاحظات
            <Text style={{fontSize:14,fontWeight:"bold",color:"red",fontFamily:"Almarai-Bold",textAlign:"right"}}
            >*</Text></Text>
<TextInput
        value={notes}
         onChangeText={setnotes}
         autoFocus={false}
        placeholder="ملاحظاتك"
        style={{width:"100%",alignSelf:"center",backgroundColor:"white",
        padding:20,borderRadius:10,flexDirection:"row",
        borderWidth:0.5,borderColor:"#3F9952",
        justifyContent:"space-between",
        height:170,
        fontSize:14,fontWeight:"bold",color:"gray",fontFamily:"Almarai-Bold",textAlign:"right",margin:20
        , outline: 'none'
        }}
      />
       <Text style={{fontSize:14,fontWeight:"bold",color:"black",fontFamily:"Almarai-Bold",textAlign:"right"}}>
           المنطقة
            <Text style={{fontSize:14,fontWeight:"bold",color:"red",fontFamily:"Almarai-Bold",textAlign:"right"}}
            >*</Text></Text>
 
     
        <Picker
    selectedValue={country}
    onValueChange={setcountry}
    style={{width:"100%",height:70,
    alignSelf:"center",backgroundColor:"white",
        padding:20,borderRadius:10,borderRadius:10,
        fontSize:16,fontWeight:"bold",color:"white",fontFamily:"Almarai-Bold",textAlign:"right",
        margin:20,borderColor:"#3F9952",color:"black"
        }}>
             <Picker.Item
        label={"اختر المنطقة"}
        value={"0"}
        
      />
    {atArray.map((status, index) => (
     status.status=="hide"?null: <Picker.Item
        key={index}
        label={status.nameAr + " " + (status.status=="busy"?"( غير متاح حاليا )":" ")}
        value={status.status=="busy"?"0":status.nameAr}
        
      />
    ))}
  </Picker>

       
<Text style={{fontSize:14,fontWeight:"bold",color:"black",fontFamily:"Almarai-Bold",textAlign:"right"}}>
           العنوان كاملا
            <Text style={{fontSize:14,fontWeight:"bold",color:"red",fontFamily:"Almarai-Bold",textAlign:"right"}}
            >*</Text></Text>
<TextInput
        value={address}
         onChangeText={setaddress}
         autoFocus={false}
        placeholder="العنوان كاملا"
        style={{width:"100%",alignSelf:"center",backgroundColor:"white",
        padding:20,borderRadius:10,flexDirection:"row",
        borderWidth:0.5,borderColor:"#3F9952",
        justifyContent:"space-between",
        height:170,
        fontSize:14,fontWeight:"bold",color:"gray",fontFamily:"Almarai-Bold",textAlign:"right",margin:20
        , outline: 'none'
        }}
      />
       
          
       
      
       
       </View>

       

   

       <View style={{
        alignItems:"center",padding:20
       }}>
         <TouchableOpacity
         style={{width:"80%",alignSelf:"center",backgroundColor:"#3F9952",
       padding:20,borderRadius:10,flexDirection:"row",
       justifyContent:"space-between"
       }} 
       onPress={()=>makeOrder()
                // console.log(convertCurrency(totalAmount,"KWD","USD")

        }
         >
          
           
           <Image
           source={whiteicons.cart}
           style={{
               width:20,
               height:20,
               }}   />
               <Text style={{fontSize:14,color:"white",fontFamily:"Almarai-Bold"}}>
            اكمال الشراء</Text>
         </TouchableOpacity>
      
       
       </View>


    
        </View>
    )
}
export default InfoCustomer;