import React,{useState,Component,useRef,useEffect,history} from 'react'

import {View,Text,ScrollView, FlatList,StyleSheet,Image,TouchableOpacity
,Linking
} from 'react-native'
import { whiteicons ,shoes, blackicon,sizes, icons, api} from '../assets/assets';

import { styles } from '../assets/styles'
import {useDispatch,useSelector} from "react-redux";
import UpNavBar from '../navbar/UpNavBar';
import { Link, useParams,useLocation } from 'react-router-dom';
import axios from 'axios';
import Loading from './Loading';
import SnapPixel from 'next-snapchat-pixel';




const Bill =()=>{
  const dispatch = useDispatch();
  const[order,setorder]=useState([])
  const location = useLocation();


  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');


  const currentDate = new Date();
  const formattedTime = currentDate.toLocaleTimeString();

    const [loading,setloading]=useState(1)
  useEffect(() => {
    // SnapPixel.init('870403a3-9ea6-4a5d-994f-6b82fd0d9dce');
    // SnapPixel.track('PURCHASE');


    window.scrollTo(0, 0);
    axios({
        method: "post",
        url: api+"getorders",
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        },
        data:{
            from :"2023-06-17",
            to:"2029-06-18",
            id:id,
            csphone:"",
            paid:"",
            state:""
        }
    })
    .then((response) => {
         console.log(response.data[0].order);
         setorder(response.data[0].order)
         setloading(0)
    })
    .catch((error) => {
        console.log(error);
    });
    
    
  
  }, [])

  
     
    

    return(
        loading==1?<Loading/>:
        <View style={styles.shoeContainerDetails}             
        >
          <UpNavBar />
      
            <Link
            style={{alignSelf:"flex-start",margin:10}}
            to="/"
            >
            <Image
           source={blackicon.blackback}
           style={{
               width:40,
               height:40
               }}   />
               
            </Link>


            <Text style={{fontWeight:"bold",fontSize:18,marginBottom:20
            ,textAlign:"left",alignSelf:"start",margin:25,color:"green"}}>
                paid done !
            </Text>

          <View style={{width:"90%",alignSelf:"center",borderWidth:0.5,borderColor:"balck"
        ,padding:10
        }}>
              <Text style={{fontWeight:"bold",fontSize:18,marginBottom:20}}>
                #IN{order.id}
            </Text>


            <View style={{flexDirection:"row",padding:10,
            borderWidth:0.3,borderColor:"black"        
        }}>
            <Text style={{fontSize:15,fontWeight:"bold"}}>
                costomer name :  
            </Text>
            <Text style={{fontSize:15,color:"gray"}}>
            {order.csname}
            </Text>
            </View>


            <View style={{flexDirection:"row",padding:10,
            borderWidth:0.3,borderColor:"black"        
        }}>
            <Text style={{fontSize:15,fontWeight:"bold"}}>
                phone :  
            </Text>
            <Text style={{fontSize:15,color:"gray"}}>
            {order.csphone}
            </Text>
            </View>
            {/* <View style={{flexDirection:"row",padding:10,
            borderWidth:0.3,borderColor:"black"        
        }}>
            <Text style={{fontSize:15,fontWeight:"bold"}}>
                country :  
            </Text>
            <Text style={{fontSize:15,color:"gray"}}>
            {order.country}
            </Text>
            </View>
           */}
          <View style={{flexDirection:"row",padding:10,
            borderWidth:0.3,borderColor:"black"        
        }}>
            <Text style={{fontWeight:"bold",fontSize:15,marginBottom:10}}>
               Date : 
            </Text>
            <Text style={{fontSize:15,color:"gray"}}>
            {order.date}| Time : {formattedTime}
            </Text>
            </View>

            <View style={{flexDirection:"row",marginBottom:10,padding:10,
            borderWidth:0.5,borderColor:"black"        
        }}>
            <Text style={{fontWeight:"bold",fontSize:15,marginBottom:10}}>
               total amount :  {order.totalAmount} KWD
            </Text>
            </View>

          </View>

           


       
       
    
    
       </View>
    )
}

export default Bill;