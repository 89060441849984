import whatsapp from './icons/whatsapp.png'
import doneicon from './icons/done.png'
import logoicon from './icons/logo.png'
import footericon from './icons/footer.png'
import headericon from './icons/header.jpg'
import headericon2 from './icons/header2.jpg'
import headericon3 from './icons/header3.jpg'


import loadingicon from './icons/loading.png'

// white icons
import whiteshoes from './icons/whiteicons/shoes.png'
import cart from './icons/whiteicons/cart.png'
import whitelang from './icons/whiteicons/lang.png'
import whitephone from './icons/whiteicons/phone.png'
import whiteemail from './icons/whiteicons/email.png'
import whiteinstagram from './icons/whiteicons/instagram.png'
import whiteprivacy from './icons/whiteicons/privacy.png'

//black icons
import blacksearch from './icons/blackicon/search.png'
import blackbag from './icons/blackicon/bag.png'
import blacklang from './icons/blackicon/earth.png'
import blackback from './icons/blackicon/back.png'
import blackback2 from './icons/blackicon/back2.png'
import nexticon from './icons/blackicon/next.png'


//pages
import Main from '../screeens/Main'
import ProductDetails from '../screeens/ProductDetails'
import Cart from '../screeens/Cart'
import InfoCustomer from '../screeens/InfoCustomer'

//customer 
import customer1 from './icons/customer/1.JPG'
import customer2 from './icons/customer/2.JPG'
import customer3 from './icons/customer/3.JPG'
import customer4 from './icons/customer/4.JPG'
import customer5 from './icons/customer/5.JPG'
import customer6 from './icons/customer/6.JPG'


//fotericon
import sellicon from './icons/footericons/sell.png'
import callicon from './icons/footericons/call.png'
import deliveryicon from './icons/footericons/delivery.png'
import paymenticon from './icons/footericons/payment.png'

//footer icons
export const footericons={
  sellicon:sellicon,
  callicon:callicon,
  deliveryicon:deliveryicon,
  paymenticon:paymenticon

}

export const icons={
    whatsapp:whatsapp,
    doneicon:doneicon,
    logoicon:logoicon,
    footericon:footericon,
    headericon:headericon,
    headericon2:headericon2,
    headericon3:headericon3,
    loadingicon:loadingicon
}

export const whiteicons={
    whiteshoes:whiteshoes,
    cart:cart,
    whitelang:whitelang,
    whitephone:whitephone,
    whiteemail:whiteemail,
    whiteinstagram:whiteinstagram,
    whiteprivacy:whiteprivacy

}
export const blackicon={
    blacksearch:blacksearch,
    blackbag:blackbag,
    blacklang:blacklang,
    blackback:blackback,
    blackback2:blackback2,
    nexticon:nexticon
}

export const shoes = [
    
    {
      id: 1,
      name_ar: "Nike Air Force 1",
      image: "https://prod.aaw.com/media/catalog/product/c/2/c20bd4b47a1e07e740e5398edcaaaed2b66c38010f8aaaec4b4a3cecda5487f2.jpeg",
      image2: "https://prod.aaw.com/media/catalog/product/e/1/e10eff0c098e562c59c0b8a70404fc98efda9facd94fec64b8c3dde281b36cc7.jpeg",
      image3: "https://prod.aaw.com/media/catalog/product/6/a/6a8158b94e2e1003bcc4898d878036a3e9233153ebc6993d0038252c7cfdbc02.jpeg",
      image4: "https://prod.aaw.com/media/catalog/product/3/8/3830608f4e68d6fb11e56e1537c73322adfa8ecd2d0b8bd578a3b2c4bd844e04.jpeg",
      amount:50,
      discount:3
    },
    {
      id: 2,
      name_ar: "Adidas Superstar",
      image: "https://prod.aaw.com/media/catalog/product/d/6/d6e40fd5e4871db1ef825423f76e6eb83a64d225eae20aa6e3b82c91f542b182.jpeg",
      image2: "https://prod.aaw.com/media/catalog/product/d/6/d6e40fd5e4871db1ef825423f76e6eb83a64d225eae20aa6e3b82c91f542b182.jpeg",
      image3: "https://prod.aaw.com/media/catalog/product/d/6/d6e40fd5e4871db1ef825423f76e6eb83a64d225eae20aa6e3b82c91f542b182.jpeg",
      image4: "https://prod.aaw.com/media/catalog/product/d/6/d6e40fd5e4871db1ef825423f76e6eb83a64d225eae20aa6e3b82c91f542b182.jpeg",
      amount:53,
      discount:3

    },

];

export const sizes = [
  { id: 5, size: 36, active: "no" },
  { id: 7, size: 37, active: "no" },
  { id: 9, size: 38, active: "no" },
  { id: 11, size: 39, active: "no" },
  { id: 13, size: 40, active: "no" },
  { id: 15, size: 41, active: "no" },
  { id: 17, size: 42, active: "no" },
  { id: 19, size: 43, active: "no" },
  { id: 21, size: 44, active: "no" },
  { id: 23, size: 45, active: "no" },
];

export const categorys = [
  {id:1,name:"الكل"},
  {id:2,name:"رجال"},
  {id:3,name:"نساء"},
  {id:4,name:"رياضي"},
]


export const Pages={
  Main:Main,
  ProductDetails:ProductDetails,
  Cart:Cart,
  InfoCustomer:InfoCustomer
}

export const customer={
  customer1:customer1,
  customer2:customer2,
  customer3:customer3,
  customer4:customer4,
  customer5:customer5,
  customer6:customer6,
}

export const version="v1.8.5"

//  export const api='http://127.0.0.1:8000/api/';
export const api='https://laravel.khaishaq8.com/api/';
