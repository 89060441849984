const initalState = {
    language:"ar"
};

const language=(state = initalState,action)=>{
const {type,payload}=action;
//payload is the despatch function to do in the state

switch(type){
    case "changelang":
        if(state.language=="ar"){return{...state, language:"en" }}
        
         else{ return{...state, language:"ar" }}

}



return state;
}
export default language;