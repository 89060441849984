import React,{useState, useEffect} from 'react'

import {View,Text,ScrollView, FlatList,Image
,TouchableOpacity,TextInput,Dimensions
} from 'react-native'
import NavBar from '../navbar/NavBar';
import UpNavBar from '../navbar/UpNavBar';
import { whiteicons , blackicon,sizes, api, icons, footericons,version } from '../assets/assets';
import { styles } from '../assets/styles';
import axios from 'axios';
import {useDispatch,useSelector} from "react-redux";
import SlideShow1 from './SlideShow1';
import Footer from './Footer';
import Loading from './Loading';
import { Link } from 'react-router-dom';
import SnapPixel from 'next-snapchat-pixel';
import { motion } from "framer-motion";



function Main() {
  const resturantTime={
    openHour: 11,
    openMeridian: "am",
    closeHour: 3,
    closeMeridian: "am"
  }
  
  function checkTime(openHour, openMeridian, closeHour, closeMeridian) {
    // Get current date and time
    const now = new Date();
  
    // Convert opening and closing hours to 24-hour format
    const openingHours = convertTimeTo24h(openHour, openMeridian);
    const closingHours = convertTimeTo24h(closeHour, closeMeridian);

  
    
    
  
    // Check if closing time is before opening time on the same day
    const isClosingBeforeOpening = closingHours < openingHours;
  
    // Adjust for days if closing time is before opening time on the same day
    const currentDate = now.getDate();
    if (isClosingBeforeOpening && now.getHours() >= closingHours) {
      now.setDate(currentDate + 1);
    } else if (isClosingBeforeOpening && now.getHours() < openingHours) {
      // It's open today because closing time from yesterday applies
    } else {
      // Standard case: opening and closing time on the same day
    }
  
    // Create Date objects for opening and closing time on the current day
    const openingTimeToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), openingHours, 0);
    const closingTimeToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), closingHours, 0);
  
    // Adjust closing time for next day if necessary
    if (isClosingBeforeOpening && now.getTime() > closingTimeToday.getTime()) {
      closingTimeToday.setDate(currentDate + 1);
    }

    function isOpen(openingTime, closingTime, nowTime) {
      // Handle the case where closing time is before opening time
      if (closingTime < openingTime) {
        return openingTime <= nowTime || nowTime < closingTime;
      } else {
        return openingTime <= nowTime && nowTime < closingTime;
      }
    }
    if (isOpen(openingTimeToday.getHours(), closingTimeToday.getHours(), now.getHours())) {
      console.log("The store is open.");
    } else {
      alert("المطعم مغلق حاليا ولكن الموقع مفتوح للطلب المسبق الرجاء كتابة ذلك في الملاحظات");
    }
  
    // Check if current time is between opening and closing time
    //const isOpen = now.getHours() >= openingTimeToday.getHours() && now.getHours() < closingTimeToday.getHours();
  console.log("open time : "+openingTimeToday.getHours()
  +"close time : "+closingTimeToday.getHours() +"now time : "+now.getHours()
  )
    
  }
  
  // Helper function to convert time string (assumes 12-hour format) to 24-hour format
  function convertTimeTo24h(hour, meridian) {
    if (meridian === "pm" && hour !== 12) {
      hour += 12;
    } else if (meridian === "am" && hour === 12) {
      hour = 0;
    }
  
    return hour;
  }
  
  // Example usage
  
  
  
  const lang=useSelector(state =>state.language.language);
  const {width}=Dimensions.get('window')

  const country=useSelector(state =>state.customerReducer.country);
  const curency=useSelector(state =>state.customerReducer.curency);
  const rates=useSelector(state =>state.customerReducer.rates);

  const [productCompanyId, setproductCompanyId] = useState("0");
const [trademarkds, settrademarks] = useState([])
const[all,setAll]=useState(true)
  const dispatch = useDispatch();


const[categories,setcategories]=useState([])

const [shoseSearch, setShoseSearch] = useState('');
const [filteredshose, setFilteredshose] = useState();
const handleSearchShose= (query) => {
  const filtered = shoes.filter((shose) =>
    shose.name_ar.toLowerCase().includes(query.toLowerCase())
  );
  setFilteredshose(filtered);
  setShoseSearch(query);
};

  

  const [categoryactive,setca]=useState({id:1,nameAr:"All"})
  const [shoes,setShoes]=useState([])
  const selectCategoryItems=(value)=>{
    setca(value)
    axios({
      method: "post",
      url: api+'searchByCategories',

      headers: { 
          'Accept': 'application/json',
          'Authorization': 'Bearer '
      },
      data:{
      search:value
      }

  })
  .then((response) => {
       console.log(response.data);
       setShoes(response.data)
       setFilteredshose(response.data)

      
  })
  .catch((error) => {
      console.log(error);
  });
  }

  const settrademarkwithaxios=(value)=>{
    setproductCompanyId(value)
    axios({
      method: "post",
      url: api+'searchBytrademark',

      headers: { 
          'Accept': 'application/json',
          'Authorization': 'Bearer '
      },
      data:{trademark:value
      ,category:categoryactive
      }

  })
  .then((response) => {
       console.log(response.data);
       setShoes(response.data)
       setFilteredshose(response.data)

      
  })
  .catch((error) => {
      console.log(error);
  });
  }

  const [search,setSearch]=useState("")
  const [loading,setloading]=useState(1);
  useEffect(() => {
     // This will depend on the current time

    //SnapPixel.init('870403a3-9ea6-4a5d-994f-6b82fd0d9dce');
   // SnapPixel.track('PAGE_VIEW');
 
    axios({
      method: "get",
      url: api+'category',
      headers: { 
          'Accept': 'application/json',
          'Authorization': 'Bearer '
      }
  })
  .then((response) => {
       console.log(response.data);
       setcategories(response.data)
       setca(response.data[0])
       axios({
        method: "post",
        url: api+'searchByCategories',

        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        },
        data:{search:response.data[0]['id']}

    })
    .then((response) => {
         console.log(response.data);
         setShoes(response.data)
         setFilteredshose(response.data)
         axios({
          method: "get",
          url: api+'trademark',
          headers: { 
              'Accept': 'application/json',
              'Authorization': 'Bearer '
          }
      })
      .then((response) => {
           console.log(response.data);
           settrademarks(response.data)
           setproductCompanyId("0")
           setloading(0)
           checkTime(resturantTime.openHour, resturantTime.openMeridian, resturantTime.closeHour, resturantTime.closeMeridian); 
      })
      .catch((error) => {
          console.log(error);
      });
        
    })
    .catch((error) => {
        console.log(error);
    });
      
  })
  .catch((error) => {
      console.log(error);
  });






  console.log("hello")}, [])




//product card function start
  const ProductsCard = ({ item }) => {
const [active,setactive]=useState({id:1,nameAr:"All"})
    return(
      <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.5 }}
    whileHover={{ scale: 1.09 }}
    whileTap={{ scale: 0.9 }}
  >

      <Link 
    

        to={"/ProductDetails/"+item.id}
        style={{textDecoration: 'none'}}

      >
        <View         style={styles.shoeContainer}>
       <Image style={styles.shoeImage} source={{ uri: item.image1 }} />
       <Text style={styles.shoeName}>{lang=="ar"? item.name_ar:item.name_en}</Text>

       <View >
      

   {
    item.discount!=0||item.discount!="0"?
    <Text style={{fontSize:15,textDecorationLine: 'line-through',color:"gray",
    textDecorationColor: 'red',}}>
      
      {Number(item.amount)} KD</Text>
    :null
   }
          <Text style={{fontSize:15,color:"#3F9952"
          ,fontFamily:"Almarai-Bold"}}>
            
            {/* {item.amount-item.discount} {curency} */}
              {Number(item.amount-item.discount) } د.ك            
            </Text>

          {/* <Text style={{fontSize:14,fontWeight:"bold"}}> </Text> */}

  
       </View>
       {/* <View style={{width:"100%",padding:5,flexDirection:"row",alignItems:"center"}}>

       <Text style={{textAlign:"left"}}>code : {item.id}</Text>

       </View> */}

       </View>

     </Link>
     </motion.div>
    )
  };
  //product card function End


  
  
  
  return (
    loading==1?
    <Loading/>
    :
    
    <View style={{backgroundColor:"#F4F4F4"}} >
         <UpNavBar />
         
         <View
         style={{
          width:"90%"
          ,height:190,backgroundColor:"white",alignSelf:"center",borderRadius:15,
          marginTop:15,
          padding:10,


          
         }}
         >
          <View style={{flexDirection:"row",width:"100%",justifyContent:"flex-end"}}>
         

          <motion.div
     whileHover={{ scale: 1.1 }}
     whileTap={{ scale: 0.9 }}
>
  <View>
  <Text style={{fontSize:18,fontFamily:"Almarai-Bold",color:"#002F25",margin:5}}>مطعم خيشة</Text>
              <Text style={{fontSize:14,fontFamily:"Almarai-Bold",color:"#3F9952",margin:5}}>
               عربي ، كويتي 
              </Text>
  </View>
              
            </motion.div>
            <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.5 }}
  >
<motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01]
      }}
      
    >
<Image
        source={icons.logoicon}
        style={{
            width:90,
            height:90,
            borderWidth:0.8,
            borderColor:"gray",borderRadius:10,margin:2
            }}   />
    </motion.div>

  </motion.div>

           

          </View>

          <View style={{flexDirection:"row",justifyContent:"space-between",marginTop:10
        ,padding:10
        }}>
         
         <View style={{
            alignItems:"center" , borderLeftWidth:0.5,borderColor:"#DEDEDE",paddingHorizontal:10
          }}>
          <Text style={{fontSize:14,fontFamily:"Almarai-Bold",color:"#3F9952",margin:3}}>
          اوقات المطعم
            </Text>
          <Text
          style={{fontSize:14,fontFamily:"Almarai-Regular",color:"#002F25",margin:3}}
          > 
        {
          resturantTime.openHour+" "+resturantTime.openMeridian+" - "+resturantTime.closeHour+" "+resturantTime.closeMeridian
        }
          </Text>

          </View>
            <View style={{
            alignItems:"center" , borderLeftWidth:0.5,borderColor:"#DEDEDE",paddingHorizontal:10
          }}>
          <Text style={{fontSize:14,fontFamily:"Almarai-Bold",color:"#3F9952",margin:3}}>
           وقت التوصيل
            </Text>
          <Text
          style={{fontSize:14,fontFamily:"Almarai-Regular",color:"#002F25",margin:3}}
          > 
          60 دقيقة
          </Text>

          </View>

          <View style={{
            alignItems:"center" , borderLeftWidth:0.5,borderColor:"#DEDEDE",paddingHorizontal:10
          }}>
          <Text style={{fontSize:14,fontFamily:"Almarai-Bold",color:"#3F9952",margin:3}}>رسوم التوصيل</Text>
          <Text
          style={{fontSize:14,fontFamily:"Almarai-Regular",color:"#002F25",margin:3}}
          > 1  د.ك</Text>

          </View>

          </View>


         </View>

         
      
    


   <View style={{
    width:"100%",height:130,backgroundColor:"#F4F4F4"
    ,padding:15
   }}>
    <View style={{flexDirection:"row",padding:10}}>
      <ScrollView 
       horizontal={true}
       showsHorizontalScrollIndicator={false}
       >
     
        <View style={{flexDirection:"row",width:"100%",alignItems:"center",justifyContent: "flex-start" }}>

      
         {categories.map((item,index)=>
         item.id==categoryactive.id?
         <TouchableOpacity
        onPress={()=>selectCategoryItems(item)}
        
        >
<Text style={{color:"#3F9952",fontSize:16,margin:5,fontFamily:"Almarai-Bold",marginRight:15}}>
{item.nameAr} 
</Text>
<motion.div layoutId="underline" style={{alignSelf:"center", width:"50%" ,height:1.5,backgroundColor:"#3F9952"
,marginRight:15
}}/>


        </TouchableOpacity>
         :
         <TouchableOpacity
         onPress={()=>selectCategoryItems(item)}

         >
           <Text
            style={{fontSize:14,color:"#383838",margin:5,fontFamily:"Almarai-Bold",marginRight:30}}
        >{item.nameAr}</Text>
         </TouchableOpacity>
         )}
          
           </View>
         
       </ScrollView>
       


</View>
    <View style={{width:"100%" ,height:45,backgroundColor:"white",margin:5
  ,borderRadius:10,alignItems:"center",flexDirection:"row",padding:5,
  shadowColor: "gray",
  shadowOffset: {
      width: 15,
      height: 15,
  },
  shadowOpacity: 0.22,
  shadowRadius: 15,
  elevation: 30,
  }}>
     <Image
        
        source={blackicon.blacksearch}
        style={{
            width:20,
            height:20
            }}   />
            
            <TextInput
                    placeholder="البحث"
                    placeholderTextColor="gray"
                    style={{width:"50%",fontFamily:"Almarai-Bold"}}
                    onChangeText={handleSearchShose}
                    autoFocus={false}

                    value={shoseSearch}
/>
    </View>
   </View>





   <ScrollView style={{width:"100%",backgroundColor:"#F4F4F4"}}
   showsHorizontalScrollIndicator={false}  // To hide the horizontal scroll indicator
   showsVerticalScrollIndicator={false}    // To hide the vertical scroll indicator
   contentContainerStyle={{ alignItems: "center",padding:10 }}

   >
   <FlatList

      data={filteredshose}
      keyExtractor={item => item.id}
      numColumns={width>500?5:2}
      renderItem={({ item ,index}) => (
        <ProductsCard item={item}  />
      )}
    
      />

   </ScrollView>


              
              
   <NavBar/>
   
   </View>
  );
}

export default Main;
