import { StyleSheet ,Dimensions} from 'react-native';
const {width}=Dimensions.get('window')

export const styles = StyleSheet.create({
    shoeContainer: {
        alignItems:"center",
       width:width>500?220:205,
        paddingVertical:5,
        margin: 5,
        borderRadius: 10,
       
        marginBottom:10,
      },
      shoeImage: {
        width:width>500?190: "80%",
        height:width>500?190: 150,
        borderRadius: 10,
        resizeMode:"stretch",
        
        
      },
      shoeName: {
       fontSize:16,
        margin:7,fontFamily:"Almarai-Bold",
       textDecoration: 'none'
      },





      shoeContainerDetails:{
       width:"100%",
        backgroundColor:"white",
       
      
    },
    shoeImageDetails:{
      width: "90%",
      height: 290,
      resizeMode:"stretch",alignSelf:"center",borderRadius:15
    },
    footerContainer: {
      backgroundColor: '#000000',
      padding: 10,
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      bottom: 0,
      
    },
    footerText: {
      color: 'white',
      fontSize: 14,
    },
    footer: {
      height:150,
      width:"100%",
      backgroundColor: 'black',
      padding:20,
      
      justifyContent: "space-between",flexDirection:"row",
    },
    contact: {
     
    },
    contactText: {
      fontSize: 16,
      fontWeight: 'bold',
      color:"white"
    },
    contactInfo: {
      fontSize: 14,
      color:"white"
    },
    address: {
      
    },
    addressText: {
      fontSize: 16,
      fontWeight: 'bold',
      color:"white"
    },
    addressInfo: {
      fontSize: 14,
      color:"white"
    },

});