import logo from './logo.svg';
import './App.css';
import React from 'react'
import Navigation from './app/navigation/Navigation';
import { Provider } from 'react-redux'
import store from './app/redux/store'

import {Route,Routes} from 'react-router-dom'
import Main from './app/screeens/Main';
import Privacy from './app/screeens/Privacy';
import ProductDetails from './app/screeens/ProductDetails';
import Cart from './app/screeens/Cart';
import InfoCustomer from './app/screeens/InfoCustomer';
import ReturnPolicy from './app/screeens/ReturnPolicy';
import Bill from './app/screeens/Bill';
import BillFailed from './app/screeens/BillFailed';
import Purchase from './app/screeens/Purchase';
import PrintBill from './app/screeens/PrintBill';


function App() {
// Function to clear complete cache data
const clearCacheData = () => {
  caches.keys().then((names) => {
      names.forEach((name) => {
          caches.delete(name);
      });
  });
  alert('Complete Cache Cleared')
};

return(
  <Provider store={store}>
  {/* <Navigation/> */}
  <Routes>


    
    <Route path='/' element={<Main/>} />
    <Route exact path='/Privacy/' element={<Privacy/>} />
    <Route  exact path='/ProductDetails/:id' element={<ProductDetails/>} />
    <Route exact path='/Cart' element={<Cart/>} />
    <Route exact path='/ProductDetails/:id/Cart' element={<Cart/>} />

    <Route  exact path='/InfoCustomer' element={<InfoCustomer/>} />
    <Route exact path='/Cart/InfoCustomer' element={<InfoCustomer/>} />
    <Route exact path='/ProductDetails/:id/Cart/InfoCustomer' element={<InfoCustomer/>} />

    <Route exact path='/ReturnPolicy' element={<ReturnPolicy/>} />
    <Route exact path='/Bill' element={<Bill/>} />
    <Route exact path='/BillFailed/:id' element={<BillFailed/>} />
    <Route exact path='/PurchaseDone/:id' element={<Purchase/>} />
    <Route exact path='/PrintBill' element={<PrintBill/>} />

    
    
    <Route path='*' element={<Main/>} />



  </Routes>
  </Provider>

  );
}

export default App;
