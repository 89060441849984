import React,{useState,Component,useRef} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
,StyleSheet,Image,Animated,Linking
} from 'react-native'
import { blackicon, icons ,whiteicons} from '../assets/assets'
import {useDispatch,useSelector} from "react-redux";
import { Link } from 'react-router-dom';

const UpNavBar = ({navigation})=>{
    const cartItems=useSelector(state =>state.cartReducer.cartItems);

    return (
    
          
           


            <View style={{
                backgroundColor:"#F4F4F4",
                justifyContent:"space-between",
                flexDirection:"row",
               width:"100%"
               ,height:100,alignItems:"center",
                shadowColor: "gray",
                shadowOffset: {
                    width: 5,
                    height: 5,
                },
                shadowOpacity: 0.22,
                shadowRadius: 15,
                elevation: 30,
                padding:10


            }}>
                
           
                {/* <Image
        
        source={blackicon.blacklang}
        style={{
            width:50,
            height:50,alignSelf:"flex-end",margin:5,
            shadowColor: "gray",
  shadowOffset: {
      width: 15,
      height: 15,
  },
  shadowOpacity: 0.22,
  shadowRadius: 15,
  elevation: 30,
            }}   /> */}

<Image
        source={icons.logoicon}
        style={{
            width:70,
            height:70,
            borderRadius:15,
           
            
            }}   />







            <Link 
            to="Cart"
            style={{flexDirection:"row",textDecoration: 'none'}}

// onPress={()=>navigation.navigate("Cart")}         
   >
    <View             style={{flexDirection:"row",textDecoration: 'none'}}
>       <Text style={{fontSize:20,color:"#3F9952"}}>{
                    cartItems.length==0?null:cartItems.length
                }</Text>
            <Image
        source={blackicon.blackbag}
        style={{
            width:30,
            height:30,
           
            
            }}   />

    </View>
         
            </Link>





           
            </View>
            
    )
}
export default UpNavBar;