import React from "react";
import { View,Text } from "react-native";
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { NavigationContainer } from '@react-navigation/native';

import { Pages } from "../assets/assets";
import Privacy from "../screeens/Privacy";
import ReturnPolicy from "../screeens/ReturnPolicy";




const Stack = createNativeStackNavigator();



  const Navigation =()=>{



    const HomeStack=()=>{
      return(
     <NavigationContainer>
        <Stack.Navigator >


           {/* Main page */}
           <Stack.Screen name="Main" component={Pages.Main} 
                    options={{
                      headerShown: false,
                    }}
                    />


                  {/* ProductDetails page */}
           <Stack.Screen name="ProductDetails" component={Pages.ProductDetails} 
                    options={{
                      headerShown: false,
                    }}
                    />

                         {/* Cart page */}
           <Stack.Screen name="Cart" component={Pages.Cart}  options={{headerShown: false, }}/>

           {/* InfoCustomer */}
           <Stack.Screen name="InfoCustomer" component={Pages.InfoCustomer}  options={{headerShown: false, }}/>
           <Stack.Screen name="Privacy" component={Privacy}  options={{headerShown: false, }}/>
           <Stack.Screen name="ReturnPolicy" component={ReturnPolicy}  options={{headerShown: false, }}/>

           
           
        </Stack.Navigator>
     </NavigationContainer>
      )}













      return (
        <HomeStack/>
    )

  }

  export default Navigation;