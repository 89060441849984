const initalState = {
    country:"",
    curency:"",
    rates:[]

};

const customerReducer=(state = initalState,action)=>{
const {type,payload}=action;
//payload is the despatch function to do in the state

switch(type){


    case "changeinfo":{
        return{...state,
            country:payload.country
            ,curency:payload.curency
        
    }
         
    }

    case "setexchange":{
        return{...state,
            rates:payload.rates        
    }
         
    }
      
        
        



}

return state;

}
export default customerReducer;