import React,{useState,Component,useRef,useEffect} from 'react'

import {View,Text,ScrollView, FlatList,StyleSheet,Image,TouchableOpacity
,Linking,useLocation
} from 'react-native'
import { whiteicons ,shoes, blackicon,sizes, icons} from '../assets/assets';

import { styles } from '../assets/styles'
import {useDispatch,useSelector} from "react-redux";
import UpNavBar from '../navbar/UpNavBar';
import { Link } from 'react-router-dom';

const ReturnPolicy =()=>{
  const dispatch = useDispatch();

    
  
     
    

    return(
        <View style={styles.shoeContainerDetails}>
          <UpNavBar />
      
            <Link to="/"
            style={{alignSelf:"flex-start",margin:10}}
            >
            <Image
           source={blackicon.blackback}
           style={{
               width:40,
               height:40
               }}   />
               
            </Link>


            <Text>
                سياسة الاسترجاع
            </Text>
<View style={{width:"100%",padding:10}}>

  <Text style={{fontSize:14,fontWeight:"bold",margin:5,textAlign:"left"}}>
  سياسة استرجاع أو استبدال الطلب تتم للحالات التالية:
  </Text>

  <Text style={{fontSize:14,fontWeight:"bold",margin:5,textAlign:"left"}}>
  - يمكن للعميل استبدال الطلب (في حال كان الخطأ من المتجر) ولا علاقة للعميل بهذا الخطأ.
  </Text>

  <Text style={{fontSize:14,fontWeight:"bold",margin:5,textAlign:"left"}}>
  - يمكن للعميل استرداد الأموال في حالة عدم توفر الطلب او القياس (أو أسباب قاهرة لا يتم بسببها تنفيذ الطلب) ويتم استرداد الأموال للعميل خلال 14 يوم كحد أقصى.<b/>
- الموقع لا يتحمل إلغاء او الاسترجاع او الاستبدال الطلب في حالة قيام العميل بإدخال (القياس او اللون او الموديل) بشكل خاطئ، (مع العلم بإمكان العميل تعديل الطلب خلال فترة 48 ساعة من اعتماده) وذلك من خلال وسائل التواصل معنا المذكورة والمحددة في الموقع  </Text>
  <Text style={{fontSize:14,fontWeight:"bold",margin:5,textAlign:"left"}}>
  - يمكن للعميل استرداد الأموال في حالة عدم توفر الطلب او القياس (أو أسباب قاهرة لا يتم بسببها تنفيذ الطلب) ويتم استرداد الأموال للعميل خلال 14 يوم كحد أقصى. <br/>
- الموقع لا يتحمل إلغاء او الاسترجاع او الاستبدال الطلب في حالة قيام العميل بإدخال (القياس او اللون او الموديل) بشكل خاطئ، (مع العلم بإمكان العميل تعديل الطلب خلال فترة 48 ساعة من اعتماده) وذلك من خلال وسائل التواصل معنا المذكورة والمحددة في الموقع.<br/>

جميع المبيعات هي سياسة نهائية<br/>
- وعليه بمجرد قيام العملاء بعملية الشراء، لا يمكنه من لإلغاء او الاسترجاع او استبدال الطلب الا خلال 48 ساعة الأولى من وقت الشراء، وعليه لا يمكنه من استرداد قيمة الطلب او إلغاءه بعد تلك الفترة المحددة (48 ساعة).<br/>
يتم تطبيق جميع سياسات المبيعات النهائية عادةً على جميع البنود بما فيها بند بيع التصفية.<br/>
كيف يعمل طلب الإلغاء والاسترجاع؟ <br/>
إذا كان العميل يرغب في استرداد الأموال، فعليه الاتصال بنا في غضون 48 ساعة من وقت إجراء الطلب، وسنرسل بريدًا إلكترونيًا إلى الإدارة، وإذا ارتأت إدارة الموقع قبول طلب الإلغاء او الاسترجاع، فسيتم ابلاغ العميل بالقبول وإعادة شحن الأموال إلى حسابك المصرفي في غضون 14 يومًا.<br/>
! ملاحظة: <br/>
لن يتم قبول أي طلب للإلغاء والاسترجاع او الاستبدال بعد 48 ساعة!<br/>
إذا كان هناك أي خطأ من الشركة المصنعة، فلديك سياسة استبدال لمدة 3 أيام من تاريخ الاستلام، مما يعني أن لديك 3 أيام بعد استلام الطلب الخاص بك لإبلاغنا بالخطأ وحق الاستبدال إذا كان هناك أي خطأ او عيب مصنعي من الشركة المصنعة.<br/>
لكي تكون مؤهلاً للإرجاع، يجب أن يكون الطلب الخاص بك في نفس الحالة التي استلمته بها ، غير ملبوس أو غير مستخدم ، مع العلامات ، وفي عبوته الأصلية. ستحتاج أيضًا إلى إيصال أو إثبات الشراء.<br/>
لبدء التبادل، يمكنك الاتصال بنا على (Flyfootstore21@gmail.com) <br/>
يمكنك دائمًا الاتصال بنا لأي سؤال حول التبادل على بريدنا الإلكتروني أو Instagram (Fly_foot.gulf ) أو الواتس اب.
  </Text>

</View>
           


       
       
    
    
       </View>
    )
}

export default ReturnPolicy;