import React from "react";

import {View,Text,ScrollView, FlatList,Image
    ,TouchableOpacity,TextInput,Dimensions
    } from 'react-native'
    import { whiteicons , blackicon,sizes, api, icons, footericons,version } from '../assets/assets';

    import { motion } from "framer-motion";

const Loading =()=>{
    return(
        <View style={{width:"100%",alignItems:"center",height:1080
        ,backgroundColor:"#13392E"
        }}>
              <motion.div
    animate={{
      scale: [0, 1],
    }}
  >
     <Image
                source={icons.logoicon}
                style={{
                    width:150,
                    height:150,
                    shadowColor: "gray",
                    shadowOffset: {
                        width: 15,
                        height: 15,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 15,
                    elevation: 30,
                    marginTop:100,
                    alignSelf:"center"
                    
                    }}   />
  </motion.div>
       
              <Text style={{color:"#3F9952",fontSize:20,margin:5,fontFamily:"Almarai-Regular"}}>
        Loading...
        </Text>
        <Text style={{color:"black",fontSize:13,margin:5,fontFamily:"Almarai-Regular"}}>
        {version}
        </Text>
                    
            </View>
    )
}
export default Loading;