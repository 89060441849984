import React,{useState,Component,useRef} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
,StyleSheet,Image,Animated,Linking
} from 'react-native'
import { icons ,whiteicons} from '../assets/assets'

const NavBar = ({})=>{
    return (
        <View style={{
            position: 'sticky',
            bottom: 10,
            width: '90%',
            alignSelf:"center",
            
            
        }}>
           <TouchableOpacity
        onPress={()=>Linking.openURL("https://wa.me/+96550202078")}

           >
           <Image
        
        source={icons.whatsapp}
        style={{
            width:50,
            height:50,
            alignSelf:"flex-end",margin:10
            }}   />

           </TouchableOpacity>
           


        
            
        </View>
    )
}
export default NavBar;